import axios from 'axios'
import { ApiEndpoint } from "config"
import { showNotification } from 'reducers/notification/actions'
import store from 'reducers'

export const GetSnapshots = (setSnapshots) => {
     axios.get(ApiEndpoint.Grafana.GetSnapshots, {
    }).then(resp => {
        store.dispatch(showNotification('Snapshots display Successfully', 'success'))
        setSnapshots(resp.data)
    }).catch(err => {
        store.dispatch(showNotification(`Error displaying snapshots.`, 'warning'))
    })
}

export const GetDashboards = (setDashboards) => {
    axios.get(ApiEndpoint.Grafana.GetDashboards, {
   }).then(resp => {
       store.dispatch(showNotification('Dashboards display Successfully', 'success'))
       setDashboards(resp.data)
   }).catch(err => {
       store.dispatch(showNotification(`Error displaying dashboards.`, 'warning'))
   })
}