import { useState, useEffect } from 'react'

import {
    Select,
    MenuItem,
    InputLabel
} from '@mui/material'

import { GetDashboards } from '../../../../../actions'

const GrafanaConfiguration = ({ id, title,
    isConfigDrawerOpen, 
    toggleConfigDrawer, 
    widgetAdvanceSettings,
    setWidgetAdvanceSettings,
    showNotification,
    ...props })=>{
    
    const [dashboards, setDashboards] = useState([])
    const [selectedDashboard, setSelectedDashboard] = useState(widgetAdvanceSettings?.grafana?.selectedDashboard)
    const [selectedDashboardUid, setSelectedDashboardUid] = useState(widgetAdvanceSettings?.grafana?.selectedDashboardUid)

    useEffect(() => {
        GetDashboards(setDashboards)
    }, [])

    useEffect(() => {
        setWidgetAdvanceSettings({
            ...widgetAdvanceSettings,
            grafana: {
                selectedDashboard: selectedDashboard,
                selectedDashboardUid: selectedDashboardUid
            }
        })
    }, [selectedDashboard])

    const setSelectedDashboardFunc = async (dashboardTitle) => {
        setSelectedDashboard(dashboardTitle)
        setSelectedDashboardUid(dashboards.find(dash => dash.title === dashboardTitle).uid)
    }

    return <div>
        <InputLabel id="selectDashboard">Select Dashboard</InputLabel>
        <Select
            value={widgetAdvanceSettings?.grafana?.selectedDashboard == null ? '' : widgetAdvanceSettings?.grafana?.selectedDashboard}
            variant='standard'
            size='small'
            fullWidth={true}
             onChange={(e) => setSelectedDashboardFunc(e.target.value)
            }
            >
                {dashboards.map(dashboard => {
                    return <MenuItem 
                    key={dashboard.id} 
                    value={dashboard.title}
                    >
                        {dashboard.title}
                    </MenuItem>    
                })}
            </Select>
           
    </div>
}

export default GrafanaConfiguration