import axios from 'axios';
export * from './api_endpoint'
export const applicationTitle = process.env.REACT_APP_Application_Title
export const tenantId = process.env.REACT_APP_TenantId
export const clientId = process.env.REACT_APP_ClientId
export const clientSecret = process.env.REACT_APP_ClientSecret
export const clientName = process.env.REACT_APP_ClientName
export const loginScope = process.env.REACT_APP_LoginScope
export const API_Endpoint = process.env.REACT_APP_API_Endpoint
export const KUSTO_CLUSTER =  process.env.REACT_APP_KUSTO_Cluster
export const KUSTO_DATABASE = process.env.REACT_APP_KUSTO_Database
export const KUSTO_TABLE = process.env.REACT_APP_KUSTO_Table


export const getNewCancelToken = () => {
    return axios.CancelToken.source();
};