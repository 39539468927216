import React, { useEffect } from 'react';
import { Grid } from '@mui/material'

const Manual = props => {

    useEffect(() => {
        document.title = 'Manual';
    }, []);

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item style={{ fontSize: '16px', maxWidth: '1000px', lineHeight: '24px' }}>
                    <h1 style={{ fontSize: '64px', lineHeight: '77px', textTransform: 'none'}}>Manual</h1>

                    <br />

                    <p>
                    <ol type="1">
                        <li>PGAN blow off [KSCFH]= Coldbox PGAN flowmeter – Pipeline PGAN flowmeter.<br /> 
                            Remark: Considering flowmeter error and disturbance, if result&lt;20KSCFH, result is set to 0.  And 20KSCFH is roughly 1% of full PGAN flow</li>
                        <li>PGAN blow off reduction [KSCFH] = PGAN blow off IF without implementing APC - current PGAN blowoff.<br />
                            Remark: PGAN blow off IF without implementing APC = 57900[Nm3/hr]/28.32[Nm3/KSCFH] – current pipeline PGAN flowmeter. 
                            As per agreement Intel & Linde  plant full load is the reference basis</li>
                        <li>GAN blow off reduction [%] =(PGAN blow off IF without implementing APC – current PGAN blow off)/ PGAN blow off IF without implementing APC</li>
                        <li>PGAN Blow off benefit [USD] = PGAN blow off reduction [KSCFH] * 28.32[Nm3/KSCFH] * 0.19403[kwh/Nm3]*0.065[USD/kWh]<br /> 
                            Remark: Spec power and power price as per agreement Intel & Linde</li>
                    </ol><br />
                    KPI - Column 2:    are current   month accumulation or average of 1st column KPIs.<br />
                    KPI - Column 3:    are previous month accumulation or average of 1st column KPIs.<br /></p>  
                    </Grid>
            </Grid>
        </>
    );
};

export default Manual;