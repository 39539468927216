const Grafana = (
    {   id, 
        widgetRefreshData, 
        width, 
        height, 
        selectedDashboard,
        dashboardUid, ...props }) =>{
    
        return <>
            {Boolean(dashboardUid) && Boolean(selectedDashboard) &&
                <iframe src={"https://demo.grafana.iot.linde-le.com/d/"+dashboardUid+"/"+selectedDashboard+"?orgId=1&kiosk=tv"} width={width} height={height} frameBorder="0"></iframe>
            }
        </>
    }

export default Grafana;

//SNAPSHOOT
// const Grafana = (
//     {   id, 
//         widgetRefreshData, 
//         width, 
//         height, 
//         snapshotKey, ...props }) =>{
    
//         return <>
//             {Boolean(snapshotKey) &&
//                 <iframe src={"https://demo.grafana.iot.linde-le.com/dashboard/snapshot/"+snapshotKey+"?orgId=1&kiosk=tv"} width={width} height={height} ></iframe>
//             }
//         </>
//     }

// export default Grafana;